<template>
  <device-card v-if="dense"
               dense
               :flat="flat"
               :device="device"
               :title="$t('device-dialog.actuators.title').toString()"
               :icon="'tune'">
    <sub-devices dense
                 :single-block="singleBlock(device?.actuators?.[0])">

      <!-- actuator devices -->
      <actuator-device v-for="(actuator, idx) in device.actuators"
                       v-bind:key="'a'+idx"
                       dense
                       v-show="showIfDense(actuator) && evalActuatorCondition(actuator)"
                       :single-block="singleBlock(actuator)"
                       :actuator="actuator"
                       :device-id="device.id"
                       :icon="icon(actuator)"
                       :label="label(actuator)">
        <component v-bind:is="component(actuator)"
                   :device="device"
                   :actuator="actuator"
                   dense/>
      </actuator-device>

      <div v-if="!showSensorsInNewRow">
        <!-- sensor devices -->
        <sensor-device v-for="(sensor, idx) in device.sensors"
                       v-bind:key="'s' + idx"
                       dense
                       v-show="showIfDense(sensor) && singleBlock(sensor)"
                       :sensor="sensor"
                       :device-id="device.id"
                       :icon="icon(sensor, sensor?.value)"
                       :label="label(sensor)"/>

        <!-- last alert -->
        <sensor-device v-if="device?.sensorLastChanged"
                       :dense="dense"
                       :value="getFormattedTimeString(device?.sensorLastChanged)"
                       icon="history"
                       :label="$t('device.sensor.last-alert').toString()"/>
      </div>
    </sub-devices>

    <!-- If show-sensors-in-new-row is true, sensors will be put in a separated sub-device component apart from the
    actuators in the dense view. This is needed if you have actuator devices (like sliders) which have single-block=true
    but also sensor devices which should all be displayed in one row with single-block=false (so far this is only
    necessary for IR Controllers) -->
    <sub-devices v-if="showSensorsInNewRow && device?.sensors"
                 dense class="pt-4"
                 :single-block="singleBlock(device?.sensors?.[0])">
      <!-- sensor devices -->
      <sensor-device v-for="(sensor, idx) in device.sensors"
                     v-bind:key="'s' + idx"
                     dense
                     v-show="showIfDense(sensor)"
                     :sensor="sensor"
                     :device-id="device.id"
                     :icon="icon(sensor, sensor?.value)"
                     :label="label(sensor)"/>

      <!-- last alert -->
      <sensor-device v-if="device?.sensorLastChanged"
                     :dense="dense"
                     :value="getFormattedTimeString(device?.sensorLastChanged)"
                     icon="history"
                     :label="$t('device.sensor.last-alert').toString()"/>
    </sub-devices>
  </device-card>
  <div v-else>
    <device-card v-if="device.actuators?.length > 0"
                 :flat="flat"
                 :device="device"
                 :title="$t('device-dialog.actuators.title').toString()"
                 :icon="'tune'">
      <sub-devices>
        <actuator-device v-for="(actuator, idx) in device.actuators"
                         v-bind:key="idx"
                         v-show="evalActuatorCondition(actuator)"
                         :actuator="actuator"
                         :device-id="device.id"
                         :hide-actions-slot="hideActionsSlot(actuator)"
                         :icon="icon(actuator)"
                         :label="label(actuator)">
          <template v-slot:content v-if="hideActionsSlot(actuator)">
            <component v-bind:is="component(actuator)"
                       :device="device"
                       :actuator="actuator"/>
          </template>
          <template v-slot:default v-else>
            <component v-bind:is="component(actuator)"
                       :device="device"
                       :actuator="actuator" @updateDevice="$emit('updateDevice')"/>
          </template>
        </actuator-device>
      </sub-devices>
    </device-card>

    <device-card v-if="device.sensors?.length > 0"
                 :class="device.actuators?.length > 0 ? 'mt-6' : ''"
                 :flat="flat"
                 :device="device"
                 :title="$t('device-dialog.sensors.title')"
                 :icon="'sensors'">
      <sub-devices :dense="dense">
        <!-- generic sensor devices -->
        <sensor-device v-for="(sensor, idx) in device.sensors"
                       v-bind:key="idx"
                       :sensor="sensor"
                       :device-id="device.id"
                       :icon="icon(sensor, sensor?.value)"
                       :label="label(sensor)"/>

        <!-- SPECIAL CASES: -->
        <!-- Reset option for meter readings for Fibaro Wallplugs -->
        <meter-total-reset v-if="meterResetAvailable(device)"
                           :value="device?.meterTotal"
                           :deviceID="device.encryptedId"
                           :last-changed="device?.lastChanged"
                           :lastReset="parseInt(device?.lastTotalReset)"/>

        <!-- last alert display for the whole device (irrespective of a specific sensor) -->
        <sensor-device v-if="device?.sensorLastChanged"
                       :dense="dense"
                       :value="getFormattedTimeString(device?.sensorLastChanged)"
                       icon="history"
                       :label="$t('device.sensor.last-alert').toString()"/>
      </sub-devices>
    </device-card>
  </div>
</template>

<script>
import deviceProperties from "@/config/deviceProperties.json";
import DeviceCard from "@/templates/components/devices/DeviceCard";
import SubDevices from "@/templates/components/devices/SubDevices";
import ActuatorDevice from "@/templates/components/devices/ActuatorDevice";
import SensorDevice from "@/templates/components/devices/SensorDevice";

// Sub-Device components
import SwitchInput from "@/templates/components/devices/actuators/SwitchInput.vue";
import SliderInput from "@/templates/components/devices/actuators/SliderInput.vue";
import FanModeInput from "@/templates/components/devices/actuators/FanModeInput.vue";
import IRControllerModeSwitch from "@/templates/components/devices/actuators/IRControllerModeSwitch.vue";
import formats from "@/scripts/formats";
import MeterTotalReset from "@/templates/components/devices/MeterTotalReset.vue";
import DoubleSwitchInput from "@/templates/components/devices/actuators/DoubleSwitchInput.vue";

export default {
  name: 'GenericDeviceCard',

  components: {
    MeterTotalReset,
    SwitchInput,
    SliderInput,
    FanModeInput,
    IRControllerModeSwitch,
    SensorDevice,
    ActuatorDevice,
    SubDevices,
    DeviceCard,
    DoubleSwitchInput
  },

  props: {
    device: Object,
    dense: Boolean,
    flat: Boolean,
    showSensorsInNewRow: Boolean
  },

  data: function () {
    return {
      switchState: this.device?.state,
      allowValueChange: true,
      cooldown: 3000,
      changeTimeout: null,
      loading: false
    }
  },

  methods: {
    updateProp() {
      this.loading = true

      this.allowValueChange = false
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout)
      }
      this.changeTimeout = setTimeout(() => {
        this.allowValueChange = true
      }, this.cooldown)

      this.$rhRequest.sendPost({
            endpoint: 'devices/update-property',
            data: {
              deviceId: this.device.id,
              state: this.switchState
            }
          },
          () => {
            this.loading = false
          },
          (error) => {
            this.$root.bisatoast.error({message: this.$t('app.generic-error')})
            console.error(error)
            this.loading = false
          })
    },

    /**
     * returns true if the hideActionsSlot has to be hidden for the sub-device
     * @param property
     * @returns {boolean}
     */
    hideActionsSlot(property) {
      return deviceProperties[property?.name]?.view?.hideActionsSlot === true
    },

    /**
     * Returns the icon key for the sub-device or null.
     *
     * @param property
     * @returns {*}
     */
    icon(property) {
      return deviceProperties[property?.name]?.icon ?? null
    },

    /**
     * returns the label for the sub-device or null
     * @param property
     * @returns {*}
     */
    label(property) {
      if (deviceProperties[property?.name]?.label?.includes('{mec-meter-type}')) {
        return this.$t(deviceProperties[property?.name]?.label.replace('{mec-meter-type}', this.device?.energyDataType))

      } else {
        return this.$t(deviceProperties[property?.name]?.label) ?? null
      }
    },

    /**
     * return the component for the sub-device or null
     * @param property
     * @returns {*}
     */
    component(property) {
      return deviceProperties[property?.name]?.component
    },

    /**
     * returns true if the sub-device should use the full width in dense view
     * @param property
     * @returns {(function(*): *)|Boolean|BooleanConstructor|*|boolean}
     */
    singleBlock(property) {
      return deviceProperties[property?.name]?.view?.singleBlock ?? false
    },

    /**
     * returns false if the sub-device should not be shown in dense view
     * @param property
     * @returns {(function(*): *)|boolean}
     */
    showIfDense(property) {
      return deviceProperties[property?.name]?.view?.showIfDense ?? false
    },

    /**
     * returns a formatted date time string
     *
     * @param timestamp
     * @returns {string}
     */
    getFormattedTimeString(timestamp) {
      if (this.dense) {
        return formats.formatDate(timestamp, this.$t('app.date-time-format.short'))
      }
      return formats.formatDate(timestamp, this.$t('app.date-time-format.long'))
    },

    meterResetAvailable(device) {
      return Object.prototype.hasOwnProperty.call(device, 'lastTotalReset')
    },

    /**
     * If the actuator has a condition defined (view.condition), it will be evaluated to determine if the actuator
     * should be rendered in the actuator list
     *
     * @param actuator
     * @return {any|boolean}
     */
    evalActuatorCondition(actuator) {
      if (!deviceProperties[actuator.name].view.condition) {
        return true
      }
      return eval(deviceProperties[actuator.name].view.condition)
    }
  },

  watch: {
    device: function () {
      if (this.allowValueChange) {
        this.switchState = this.device?.state
      }
    }
  }
}

</script>
